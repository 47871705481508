import Layout from "src/layouts/LayoutWhiteHeader";
import SEO from "src/components/generic/SEO/SEO";
import React, { Component } from "react";

const Index = () => {
	return (
		<Layout>
			<SEO pageTitle={"Privacy Policy"} />

			<section className="container m-t-lg m-b-xl">
				<h1>Privacy Policy</h1>
				<h2>WHO ARE WE?</h2>
				<p>
					We are committed to protecting and respecting your privacy.
				</p>
				<p>
					This policy sets out the basis on which any personal data we
					collect from you, or that you provide to us, will be
					processed by us.
				</p>
				<p>
					Please read the following carefully to understand our views
					and practices regarding your personal data and how we will
					treat it. By visiting pipedout.com (“our site”) you are
					accepting and consenting to the practices described in this
					policy.
				</p>
				<h2>CONTROLLER</h2>
				<p>
					Piped Out is the controller and responsible for your
					personal data. (Referred to as &quot;we&quot;,
					&quot;us&quot; and &quot;Company&quot; in this privacy
					policy.)
				</p>
				<h2>COMPANY DETAILS</h2>
				<p>
					Piped Out is the trading name of Pipedout LTD a company
					incorporated in the UK with company number: 12820465.
				</p>
				<h2>INFORMATION WE MAY COLLECT FROM YOU</h2>
				<p>We may collect and process the following data about you:</p>
				<strong>Information you give us.</strong>
				<p>
					You can visit our site without telling us who you are or
					revealing any information by which someone could identify
					you as a specific, identifiable individual (which we will
					refer to in this Privacy Policy collectively as
					&quot;Personal Data&quot;).
				</p>
				<p>
					If you choose to provide we may collect the following
					different kinds of personal data:
				</p>
				<ul>
					<li>
						<strong>Identifying Data:</strong> Names, user names,
						date of birth.
					</li>
					<li>
						<strong>Contact Data:</strong> Email and phone numbers.
					</li>
					<li>
						<strong>Professional Data:</strong> Role & company
						worked.
					</li>
				</ul>
				<strong>Information we collect about you.</strong>
				<p>
					We and our third-party service providers (including any
					third-party content, advertising, and analytics providers)
					automatically collect certain information from your device
					or web browser when you interact with our site to help us
					understand how users use our site (which we will refer to in
					this Privacy Policy collectively as &quot;Usage Data&quot;).{" "}
				</p>
				<strong>Information we receive from other sources.</strong>
				<p>
					We may receive information about you if you use any of the
					other websites we operate or the other services we provide.
					We are also working closely with third parties (including,
					for example, business partners, sub-contractors in
					technical, payment and delivery services, advertising
					networks, analytics providers, search information providers)
					and may receive information about you from them.
				</p>
				<p>
					<strong>Piped Out Search Console Helper</strong>
				</p>
				<p>
					Piped Out Search Console Helper uses Google OAuth2.
					Authenticating with Google OAuth2 allows Piped Out Search
					Console Helper to identify you and give you access to the
					service.
				</p>
				<p>
					Piped Out Search Console Helper can access some data stored
					by these third-party services, for registration or
					identification. Piped Out Search Console Helper has read
					access to Google Search Console profiles which it uses to
					provide a service.
				</p>
				<p>
					Google OAuth2 may process data as specified in it&apos;s own
					privacy policy.
				</p>
				<h2>IP ADDRESSES AND COOKIES</h2>
				<p>
					Our website uses cookies to distinguish you from other users
					of our website. This helps us to provide you with a good
					experience when you browse our website and also allows us to
					improve our site.{" "}
				</p>
				<p>
					By continuing to browse the site, you are agreeing to our
					use of cookies. A cookie is a small file of letters and
					numbers that we store on your browser or the hard drive of
					your computer if you agree. Cookies contain information that
					is transferred to your computer&apos;s hard drive.
				</p>
				<h2>HOW DO WE USE YOUR INFORMATION</h2>
				<p>
					<strong>Getting in contact</strong>
				</p>
				<p>
					- we may process data enabling us to get in touch with you.
					This may include your name or email.
				</p>
				<strong>Keeping you updated</strong>
				<p>- to notify you about changes to our service;</p>
				<strong>Marketing</strong>
				<p>
					- with your consent to use this information to contact you
					about our products and other information which we feel might
					be of interest to you. You can withdraw your consent at any
					time and we will stop contacting you.
				</p>
				<strong>Administration & functionality</strong>
				<p>
					- to administer our site and for internal operations,
					including troubleshooting, data analysis, testing, research,
					statistical and survey purposes;
				</p>
				<p>
					- to improve our site to ensure that content is presented in
					the most effective manner for you and for your computer;
				</p>
				<p>
					- to allow you to participate in interactive features of our
					service, when you choose to do so;
				</p>
				<p>
					- as part of our efforts to keep our site safe and secure;
				</p>
				<strong>Information we receive from other sources.</strong>
				<p>
					We may combine this information with information you give to
					us and information we collect about you. We may us this
					information and the combined information for the purposes
					set out above (depending on the types of information we
					receive).
				</p>
				<h2>WHERE DO WE SHARE YOUR INFORMATION</h2>
				<p>
					We may share your personal information with any member of
					our group, which means our subsidiaries, our ultimate
					holding company and its subsidiaries, as defined in section
					1159 of the UK Companies Act 2006.
				</p>
				<p>
					<strong>Personal data</strong>
				</p>
				<p>
					There are several non-standard circumstances, where we might
					end up having to share Personal Data.
				</p>
				<ol></ol>- If the Company or substantially all of its assets are
				acquired by a third party, in which case personal data held by
				it about its customers will be one of the transferred assets. -
				If we are under a duty to disclose or share your personal data
				in order to comply with any legal obligation, or in order to
				enforce or apply our terms of use and other agreements; or to
				protect the rights, property, or safety of the Company, our
				customers, or others. This includes exchanging information with
				other companies and organisations for the purposes of fraud
				protection and credit risk reduction.
				<p>
					<strong>Usage data</strong>
				</p>
				<p>
					We shared aggregated usage data about our website with
					various analytics and search engine providers to allow the
					site to function and to help us better understand and
					improve the site.
				</p>
				<h2>WHERE WE STORE YOUR PERSONAL DATA</h2>
				<p>
					The data that we collect from you may be transferred to, and
					stored at, a destination outside the European Economic Area
					(&quot;EEA&quot;). It may also be processed by staff
					operating outside the EEA who work for us or for one of our
					suppliers. Such staff maybe engaged in, among other things,
					the fulfilment of your order, the processing of your payment
					details and the provision of support services. By submitting
					your personal data, you agree to this transfer, storing or
					processing. We will take all steps reasonably necessary to
					ensure that your data is treated securely and in accordance
					with this privacy policy.
				</p>
				<p>
					All information you provide to us is stored on our secure
					servers. Where we have given you (or where you have chosen)
					a password which enables you to access certain parts of our
					site, you are responsible for keeping this password
					confidential. We ask you not to share a password with
					anyone.
				</p>
				<p>
					Unfortunately, the transmission of information via the
					internet is not completely secure. Although we will do our
					best to protect your personal data, we cannot guarantee the
					security of your data transmitted to our site; any
					transmission is at your own risk. Once we have received your
					information, we will use strict procedures and security
					features to try to prevent unauthorised access.
				</p>
				<h2>YOUR RIGHTS</h2>
				<p>
					By law, you can ask us what information we hold about you,
					and you can ask us to correct it if it is inaccurate. If we
					have asked for your consent to process your personal data,
					you may withdraw that consent at any time.
				</p>
				<p>
					If we are processing your personal data for reasons of
					consent or to fulfil a contract, you can ask us to give you
					a copy of the information in a machine-readable format so
					that you can transfer it to another provider.
				</p>
				<p>
					If we are processing your personal data for reasons of
					consent or legitimate interest, you can request that your
					data be erased.
				</p>
				<p>
					You have the right to ask us to stop using your information
					for a period of time if you believe we are not doing so
					lawfully.
				</p>
				<p>
					Finally, in some circumstances you can ask us not to reach
					decisions affecting you using automated processing or
					profiling.
				</p>
				<p>
					To submit a request regarding your personal data by email,
					post or telephone, please use the contact information
					provided above at the start of this policy.
				</p>
				<h2>CHANGES TO OUR PRIVACY POLICY</h2>
				<p>
					Any changes we may make to our privacy policy in the future
					will be posted on this page and, where appropriate, notified
					to you by e-mail. Please check back frequently to see any
					updates or changes to our privacy policy.
				</p>
				<h2>HOW TO CONTACT US</h2>
				<p>
					If you&apos;d like to contact us with any questions or
					comments please send an e-mail to our data privacy manager,
					Dominic Woodman - dom (at) pipedout.com.
				</p>
				<p>
					You can also write our registered address: 10 South Close,
					Ipswich, Suffolk, IP4 2TH.
				</p>
			</section>
		</Layout>
	);
};

export default Index;
